<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
@font-face {
  font-family: "Pretendard-Regular";
  src: url("@/assets/font/Pretendard-Regular.woff") format("woff");
  font-style: normal;
}
</style>
